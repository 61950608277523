import React, { useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { CancelPresentationRounded } from '@mui/icons-material';
import CanvasJSReact from '../../../ui/canvasjs/canvasjs.react';
import PropTypes from 'prop-types';
import ModalDialog from '../../ModalDialog';
import styles from './styles';
import LinearScaleIcon from '@mui/icons-material/LinearScale';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function DepthVsDensityChartDialog({ onCloseClick, isOpen, data }) {
  const classes = styles();
  const chartData = [...data.run_response.depth_points];

  const [chartConfig, setChartConfig] = useState([
    {
      name: 'ESD',
      key: 'esd',
      color: '#ff4d4d',
      hide: false
    },
    {
      name: 'ESD with SBP',
      key: 'esd_with_sbp',
      color: '#ff0000',
      hide: false
    },
    {
      name: 'ECD',
      key: 'ecd',
      color: '#80b7ff',
      hide: false
    },
    {
      name: 'ECD with SBP',
      key: 'ecd_with_sbp',
      color: '#006eff',
      hide: false
    },
  ]);

  const CustomLegend = () => {
    const updateHide = (key, hideValue) => {
      setChartConfig(prevItems =>
        prevItems.map(item =>
          item.key === key ? { ...item, hide: hideValue } : item
        )
      );
    };

    return (
      <Grid container>
        {chartConfig.map((item) => (
          <Box
           key={item.name}
           className={classes.legendItem}
           sx={{ opacity: item.hide ? 0.4 : 1 }}
           onClick={() => {
             updateHide(item.key, !item.hide);
           }}
           >
            <LinearScaleIcon fontSize='large' sx={{ color: item.color, mr: 1 }} />
            {item.name}
          </Box>
        ))}
      </Grid>
    );
  };

  const options = {
    theme: 'light2',
    animationEnabled: true,
    zoomEnabled: true,
    exportEnabled: true,
    zoomType: 'y',
    toolbar: {
      itemBackgroundColor: '#d3d3d3',
      itemBackgroundColorOnHover: '#3e3e3e',
      buttonBorderColor: '#3e3e3e',
    },
    backgroundColor: '#FFF',
    axisX: {
      viewportMinimum: 0,
      viewportMaximum: 0,
      interval: 1,
      valueFormatString: '0.0',
      labelFontSize: 16,
      gridThickness: 1,
      title: 'EMW (ppg)',
      titleFontSize: 22,
    },
    axisY: {
      viewportMinimum: null,
      viewportMaximum: null,
      title: 'MD (ft)',
      titleFontSize: 22,
      labelFontSize: 16,
      includeZero: false,
      valueFormatString: '0',
      reversed: true,
      labelPlacement: 'outside',
      gridThickness: 1,
    },
    height: 800,
    legend: {
      fontSize: 20
    },
    toolTip: {
      contentFormatter: function (e) {
        let content = ' ';
        content += 'MD' + ' : ' + e.entries[0].dataPoint.y + ' ft';
        content += '<br/>';
        content += e.entries[0].dataSeries.name + ' : ' + e.entries[0].dataPoint.x + ' ppg';
        return content;
      }
    }
  };

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
            {`${data?.name}`}
          </Grid>
          <Grid item>
            <CustomLegend />
          </Grid>
          <Grid item>
          <IconButton
              sx={{ mr: 2 }}
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
  <ModalDialog
    title={getModalTitle()}
    isOpen={isOpen}
    onClose={onCloseClick}
    dialogClass={classes.dialogModal}
  >
    <Grid
     sx={{
       p: 3,
       pt: 4,
       '.canvasjs-chart-toolbar': {
         top: '-30px !important',
       },
     }}
    >
    <CanvasJSChart
        options={{
          ...options,
          data: chartConfig.map((config) => {
            if (config.hide) return {};
            return {
              type: 'line',
              name: config.name,
              showInLegend: false,
              dataPoints: chartData.map(item => ({
                x: item[config.key],
                y: item.md
              })),
              color: config.color,
            };
          })
        }}
      />
    </Grid>
  </ModalDialog>
  );
}

DepthVsDensityChartDialog.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};

export default DepthVsDensityChartDialog;
