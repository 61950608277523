import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  initialData: [],
  PODSave: false
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.USERS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: ''
      };
    case REDUX_ACTIONS.USERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.users],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: ''
      };
    case REDUX_ACTIONS.USERS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: []
      };
    case REDUX_ACTIONS.USERS_LIST_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: []
      };
    case REDUX_ACTIONS.USERS_POD_UPLOAD_LOADING:
      return {
        ...state,
        PODSave: true
      };
    case REDUX_ACTIONS.USERS_SET_POD: {
      const userIndex = state.data.findIndex(item => item._id === action.payload.userId);
      const orderIndex = userIndex !== -1 ? state.data[userIndex].orders.findIndex(order => order._id === action.payload.orderId) : -1;
      if (userIndex !== -1 && orderIndex !== -1) {
        const updatedUserDetails = [...state.data];
        updatedUserDetails[userIndex].orders[orderIndex].POD = action.payload.POD;
        return {
          ...state,
          PODSave: false,
          data: updatedUserDetails
        };
      }
      return state;
    }
    case REDUX_ACTIONS.USERS_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default usersReducer;
