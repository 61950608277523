/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../../ui/ModalDialog';
import { Box, CardMedia, CircularProgress, Grid, IconButton, Input, InputAdornment, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../hooks/useAuth';
import {
  listOrderParts,
  postOrderParts,
  listOrders,
  downloadOrderPDF,
  addToGreenList,
  removeFromGreenList
} from '../../../../store/actions/orders';
import { isFullArray } from '../../../../utils/validators';
import { cartDialogSort } from '../../../../utils/cartDialogSort';
import OrderProcessingModalDialog from '../../../ui/OrderProcessingModalDialog';
import {
  DeleteRounded,
  ContentCopy,
  CancelPresentationRounded,
  ShoppingCartCheckoutRounded,
  PrintRounded,
  CallSplitRounded,
  AttachMoneyOutlined,
  CallMergeRounded
} from '@mui/icons-material';
import { isMobile } from '../../../../utils/isMobile';
import SliderOrderConfirmModalDialog from '../../slideOrderQuoteConfirmationModal';
import styles from './styles';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import { enqueueSnackbar } from '../../../../store/actions/snackbar';
import { splitOrdersByMaxAmount } from '../../../../utils/cartSplitOrders';
import SplitMobileOrders from './SplitMobileOrders';

const OrdersCartMobileDialog = ({
  isOpen,
  partIDs,
  orderData,
  onCloseClick,
  onActionClick,
  saveButtonText,
  maxOrderAmount,
  setMaxOrderAmount,
  splitOrder,
  setSplitOrder
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { loading, greenPartsDetails, orderPartsPostSuccess } = useSelector(
    (state) => state.orders
  );
  const [cart, setCart] = useState([]);
  const [cartPrice, setCartPrice] = useState(0);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(null);
  const orders = useSelector((state) => state.orders);
  const mobile = isMobile();
  const [sliderModal, setSliderModal] = useState(false);
  const [maxPrice, setMaxPrice] = useState('');
  const [groupedOrders, setGroupedOrders] = useState([]);
  const classes = styles();

  const handleDownloadOrderPDF = (orderId) => {
    dispatch(downloadOrderPDF(orderId));
  };

  useEffect(() => {
    if (orderData && Object.keys(orderData)?.length) {
      setDialogTitle(getDialougeTitleWithPrint(orderData));
    }
  }, [orders.printState]);

  const getDialougeTitleWithPrint = ({ _id: orderId, timestamp, quantity, price }) => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {`Date: ${timestamp}, Order Id: ${orderId}, Quantity: ${quantity}, Total Price: $${price.toFixed(2)}`}
          </Grid>
          <Grid item>
            <IconButton
              disabled={orders.printState === 'loading'}
              onClick={() => {
                handleDownloadOrderPDF(orderId);
              }}
            >
              <PrintRounded fontSize='large' />
            </IconButton>
            <IconButton
              onClick={() => {
                handleOrderButtonClick();
              }}
              >
              <ShoppingCartCheckoutRounded fontSize='large'/>
            </IconButton>
            <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(listOrderParts(user.companyId, partIDs));
      if (saveButtonText) {
        setDialogTitle(getDialougeTitleWithPrint(orderData));
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (orderPartsPostSuccess) {
      onCloseClick();
      setIsProcessing(false);
    }
  }, [orderPartsPostSuccess]);

  useEffect(() => {
    if (isFullArray(greenPartsDetails) && !loading) {
      const cartDetails = partIDs
        .map((id) => greenPartsDetails.find((part) => part._id === id))
        .filter((part) => !!part)
        .map((part) => ({ ...part, rowId: `${Math.random(1000)}_${part._id}` }));

      setCart(cartDetails.sort(cartDialogSort));

      const highestPrice = Math.max(...cartDetails.map(product => parseFloat(product.Price)));
      setMaxPrice(highestPrice);

      const cartTotalPrice = cartDetails
        ?.map((i) => +i?.Price)
        .reduce((sum, num) => sum + num, 0);
      setCartPrice(cartTotalPrice.toFixed(2));
      const sortedProducts = cartDetails.sort(cartDialogSort).slice().sort((a, b) => parseFloat(b.Price) - parseFloat(a.Price));
      const groupedOrders = splitOrdersByMaxAmount(sortedProducts, maxOrderAmount);
      setGroupedOrders(groupedOrders);
    }
  }, [greenPartsDetails, loading]);

  const handleOrderButtonClick = async (e) => {
    setSliderModal(true);
    onActionClick(e);
  };

  const placeOrder = () => {
    dispatch(postOrderParts(user, cart));
    setSliderModal(false);
    setIsProcessing(true);
    setLoadingDialog(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialog(false);
    onCloseClick();
    dispatch(listOrders(user));
  };

  const getModalTitle = () => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item className={classes.inputContainer}>
          <Grid item>
            <Input
            disabled={splitOrder}
            placeholder="Max Order Amount"
            value={maxOrderAmount}
            onChange={(e) => {
              setMaxOrderAmount(e.target.value);
            }}
            className={classes.inputFieldMaxOrder}
            type="number"
            startAdornment={<InputAdornment sx={{ fontSize: '16px' }} position="start"><AttachMoneyOutlined /></InputAdornment>}
            />
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
             disabled={!maxOrderAmount}
              onClick={() => {
                splitOrders();
              }}
            >
              {splitOrder ? <CallMergeRounded fontSize='medium' /> : <CallSplitRounded fontSize='medium' />}
            </IconButton>
            <IconButton
              onClick={() => {
                onCloseClick();
                setSplitOrder(false);
              }}
            >
              <CancelPresentationRounded fontSize={mobile ? 'medium' : 'large'} />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const createDialogTitle = (orderData, cart, cartPrice) => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
          {`Date: ${orderData.timestamp}, Order Id: ${orderData._id}, Quantity: ${cart.length}, Total Price: $${cartPrice}`}
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                handleOrderButtonClick();
              }}
            >
              <ShoppingCartCheckoutRounded fontSize={mobile ? 'medium' : 'large'} />
            </IconButton>
            <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize={mobile ? 'medium' : 'large'} />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const removeRow = (row) => {
    const newCart = [...cart];
    const filtered = newCart.filter(item => item.rowId !== row?.rowId);
    setCart(filtered);
    const cartTotalPrice = filtered
      ?.map((i) => +i?.Price)
      .reduce((sum, num) => sum + num, 0);
    const sortedCart = filtered.sort(cartDialogSort);
    const highestPrice = Math.max(...sortedCart.map(product => parseFloat(product.Price)));
    setMaxPrice(highestPrice);
    setCart(sortedCart);
    setCartPrice(cartTotalPrice.toFixed(2));
    dispatch(removeFromGreenList(row._id));
    if (saveButtonText) {
      setDialogTitle(createDialogTitle(orderData, filtered, cartTotalPrice.toFixed(2)));
    }
    if (sortedCart.length === 0) {
      onCloseClick();
    }
  };

  const duplicateRow = (row) => {
    const newCart = [...cart, { ...row, rowId: `${Math.random(1000)}_${row._id}` }];
    const cartTotalPrice = newCart
      ?.map((i) => +i?.Price)
      .reduce((sum, num) => sum + num, 0);
    const sortedCart = newCart.sort(cartDialogSort);
    setCart(sortedCart);
    setCartPrice(cartTotalPrice.toFixed(2));
    dispatch(addToGreenList(row._id));
    if (saveButtonText) {
      setDialogTitle(createDialogTitle(orderData, newCart, cartTotalPrice.toFixed(2)));
    }
  };

  const splitOrders = () => {
    dispatch({
      type: REDUX_ACTIONS.SET_SHOPPING_CART_PREFERENCES,
      payload: { maxOrderAmount },
    });
    if (splitOrder) {
      setSplitOrder(!splitOrder);
      return;
    }
    if (maxOrderAmount < maxPrice) {
      dispatch(
        enqueueSnackbar(
          'Max Order Amount value is less than price of highest priced part, Could not split the orders',
          'error',
          new Date().getTime() + Math.random()
        )
      );
    } else {
      setSplitOrder(!splitOrder);
      const sortedProducts = cart.slice().sort((a, b) => parseFloat(b.Price) - parseFloat(a.Price));
      const groupedOrders = splitOrdersByMaxAmount(sortedProducts, maxOrderAmount);
      setGroupedOrders(groupedOrders);
    }
  };

  const renderActions = ({
    id,
    row,
    flexEnd
  }) => {
    return (
      <Grid container justifyContent={flexEnd ? 'flex-end' : 'center'} sx={{ marginTop: flexEnd && '14px' }}>
        <IconButton
        variant="solid"
        onClick={() => removeRow(row, id)}
      >
        <DeleteRounded sx={{ fontSize: flexEnd && '30px' }}/>
      </IconButton>
        <IconButton
          variant="solid"
          onClick={() => duplicateRow(row, id)}
        >
          <ContentCopy sx={{ fontSize: flexEnd && '30px' }}/>
        </IconButton>
      </Grid>
    );
  };

  const CartCard = ({ data }) => {
    const keysToShow = ['Name', 'Size', 'Price', 'Description', 'Vendor'];
    return (
      <Paper key={data._id} elevation={3} sx={{ backgroundColor: '#f2f2f2', padding: 4, margin: '14px', position: 'relative' }}>
        {Object.entries(data).map(([key, value]) => {
          if (keysToShow.includes(key)) {
            return (
              <Box key={key}>
                <Typography variant='h6' sx={{ textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '10px' }}>{key}</Typography>
                <Typography variant='h6' sx={{ marginBottom: '14px', color: '#666' }}>{value}</Typography>
              </Box>
            );
          }
          return null;
        })}
        {renderActions({ id: data._id, row: data, flexEnd: true })}
        <CardMedia
          component="img"
          image={data.Image}
          alt={data.Name}
          sx={{ objectFit: 'contain', height: 'auto', width: '50%', position: 'absolute', top: 4, right: 4 }}
        />
      </Paper>
    );
  };

  return (
      <>
        <ModalDialog
          isFullScreen
          title={dialogTitle || getModalTitle()}
          isOpen={isOpen}
          onClose={onCloseClick}
        >
        {!orderData && !splitOrder && <Grid sx={{ padding: '0 14px' }} container alignItems="center" justifyContent="space-between">
          <Grid item className={classes.displayQuants}>
            {`Quantity: ${cart?.length}, Total Price: $${cartPrice}`}
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                handleOrderButtonClick();
              }}
            >
              <ShoppingCartCheckoutRounded fontSize='large'/>
            </IconButton>
          </Grid>
          </Grid>}
          {!splitOrder && <Grid>
            {cart?.map((item) => {
              return <CartCard key={item?.rowId} data={item} />;
            })};
          </Grid>}
          {splitOrder
            ? (
            <>
            {loading
              ? (
              <Box
               sx={{
                 width: '100%',
                 height: '300px',
                 display: 'flex',
                 justifyContent: 'center',
                 alignItems: 'center'
               }}>
                 <CircularProgress />
               </Box>
                )
              : (
              <>
              {groupedOrders.map((item, index) => {
                return (
                <>
                <SplitMobileOrders
                  key={index}
                  cartItems={item}
                  setSplitOrder={setSplitOrder}
                  setGroupedOrders={setGroupedOrders}
                  maxOrderAmount={maxOrderAmount}
                />
                </>
                );
              })}
              </>
                )}
            </>
              )
            : null}
        </ModalDialog>
        <OrderProcessingModalDialog
          isOpen={loadingDialog}
          isProcessing={isProcessing}
          closeLoadingDialog={closeLoadingDialog}
        />
        <SliderOrderConfirmModalDialog
            title={'Order'}
            placeOrder={placeOrder}
            isOpen={sliderModal}
            setSliderModal={setSliderModal}
          />
      </>
  );
};

OrdersCartMobileDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  partIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  orderData: PropTypes.any,
  onCloseClick: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
  saveButtonText: PropTypes.string,
};

export default OrdersCartMobileDialog;
