/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Grid, Typography, IconButton, gridClasses } from '@mui/material';
import ModalDialog from '../ui/ModalDialog/index';
import { CancelPresentationRounded, DeleteRounded, EditOutlined, SaveRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import Datagrid from '../ui/Datagrid';
import columns from './table_config';
import { renderCell } from '../ui/Datagrid/table_config';
import { csvFileToArray } from '../../utils/csvFileToArray';
import styles from './style';
import ReactJson from 'react-json-view';
import DrillFluidDetails from './drillFluidDetails';
import { addNewDrillFluidRow, createDrillFluid, deleteDrillFluidRow, importDrillFluidDetails, listDrillFluid, listDrillFluidDetails, updateDrillFluid } from '../../store/actions/wellsDrillFluid';
import SelectEditInputTextField from '../ui/SelectEditInputTextField';
import { enqueueSnackbar } from '../../store/actions/snackbar';
import { REDUX_ACTIONS } from '../../shared/config/constants';
const DrillFluidModal = ({
  onCloseClick,
  drillFluidData,
  isOpen
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const drillFluid = useSelector((state) => state.wellsDrillFluid);
  const apiRef = useGridApiRef();
  const fileReader = new FileReader();
  const [editDialog, setEditDialog] = useState(false);
  const [editData, setEditData] = useState({});
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});

  useEffect(() => {
    if (!drillFluid.loading) {
      dispatch(listDrillFluid(drillFluidData?.row?._id));
    }
  }, []);

  useEffect(() => {
    if (drillFluid?.data) {
      setProperties(drillFluid?.data);
    }
  }, [drillFluid?.data]);

  useEffect(() => {
    if (properties[0]?.MD === '') {
      const id = properties[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 0
        });
        apiRef.current.setCellMode(id, 'MD', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'MD');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties?.length]);

  const handleAddDrillFluid = () => {
    dispatch(addNewDrillFluidRow());
  };

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {drillFluidData?.row?.name || ''}
          </Grid>
          <Grid item>
          <IconButton
              onClick={onCloseClick}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const removeDrillFliud = (row) => {
    if (row?.isNewRow) {
      const updatedDrillFluid = properties.filter(item => item._id !== row?._id);
      setProperties(updatedDrillFluid);
      dispatch(listDrillFluid(drillFluidData?.row?._id));
    } else {
      dispatch(deleteDrillFluidRow(row?._id, drillFluidData?.row?._id));
    }
  };

  const handleSave = (row) => {
    if (row?.MD) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      if (row?.isNewRow) {
        delete row?.isNewRow;
        dispatch(createDrillFluid(row, drillFluidData?.row?._id));
      } else {
        dispatch(updateDrillFluid({ ...row, wellsInfoId: drillFluidData?.row?._id }));
      }
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          'Please enter the MD details',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const renderActionsCell = ({ row }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
          variant="solid"
          disabled={drillFluid?.loading || !row?.MD}
          onClick={() => {
            if (!row?.isNewRow) {
              dispatch(listDrillFluidDetails(row?._id));
            } else {
              delete row?.isNewRow;
              dispatch(createDrillFluid(row, drillFluidData?.row?._id, 'details'));
              dispatch({
                type: REDUX_ACTIONS.WELLS_DRILL_FLUID_DETAILS_LIST_SUCCESS,
                payload: {
                  data: [],
                  paginatedElements: 0,
                },
              });
            }
            setEditData(row);
            setEditDialog(true);
          }}
        >
          <EditOutlined />
        </IconButton>
        <IconButton
          disabled={drillFluid?.loading}
          variant="solid"
          onClick={() => {
            handleSave(row);
          }}
        >
          <SaveRounded />
        </IconButton>
        <IconButton
          disabled={drillFluid?.loading}
          variant="solid"
          onClick={() => {
            removeDrillFliud(row);
          }}
        >
          <DeleteRounded />
        </IconButton>
      </Grid>
    );
  };

  const uploadCSVFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        const drillFluidList = csvFileToArray(csvOutput);
        dispatch(importDrillFluidDetails(drillFluidList));
      };
      fileReader.readAsText(file);
    }
  };

  const RenderJsonField = ({ value }) => {
    const data = useRef(value);
    return (
      <ReactJson
        src={data.current}
        name={false}
        sortKeys={true}
        collapsed={true}
        displayDataTypes={false}
        quotesOnKeys={false}
      />
    );
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: Number(value)
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField numberFormatOnly {...params} updateData={updateData} />;
  };

  if (apiRef.current === null) apiRef.current = {};
  return (
    <ModalDialog
      title={getModalTitle()}
      isOpen={isOpen}
      onClose={onCloseClick}
      dialogClass={classes.dialogModal}
    >
      <Grid container>
      <Grid item className={classes.customTool} xs={1} justifyContent="space-between" >
            <Grid item>
              <Typography variant='button' fontWeight='700' onClick={handleAddDrillFluid} className={drillFluid.loading || properties[0]?.isNewRow ? classes.disableIcon : ''}>+ Add</Typography>
            </Grid>
            <Grid item>
              <label className={classes.importLabel} htmlFor='getCSVFile'>Import</label>
              <input type='file' id='getCSVFile' accept={'.csv'} style={{ display: 'none' }} onChange={uploadCSVFile} />
            </Grid>
          </Grid>
        <Grid item xs={12}>
          <Datagrid
            apiRef={apiRef}
            data={properties}
            loading={drillFluid.loading}
            columns={columns(renderCell, RenderJsonField, renderActionsCell, renderValueCell)}
            onRowClick={(params, evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            }}
            disableStickyHeader={false}
            autoHeight={false}
            getRowId={(row) => row._id}
            getRowHeight={() => 'auto'}
            sx={{
              marginLeft: '-6px',
              '.MuiDataGrid-cell': {
                fontSize: '1rem',
                fontWeight: 900
              },
              '.MuiDataGrid-columnHeaderTitle': {
                fontSize: '1.2rem',
                fontWeight: '900 !important',
              },
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
            autoheight
            editMode="cell"
            height={'50vh'}
            getRowClassName={(params) => {
              return invalidRows[params.id] ? classes.invalidRow : '';
            }}
            pageSize={100}
            toolbar={{
              options: {
                columns: false,
                filters: false,
                density: false,
                export: false
              },
            }}
          />
        </Grid>
        {editDialog && editData?.MD
          ? (
        <DrillFluidDetails
          drillFluidData={drillFluidData}
          open={editDialog}
          handleClose={() => {
            setEditDialog(false);
            setEditData({});
            dispatch({
              type: REDUX_ACTIONS.WELLS_DRILL_FLUID_SET_ID,
              payload: {
                data: '',
              },
            });
          }}
          data={editData}
        />
            )
          : null}
      </Grid>
    </ModalDialog>
  );
};
DrillFluidModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  drillFluidData: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};
DrillFluidModal.defaultProps = {
  drillFluidData: {}
};
export default DrillFluidModal;
