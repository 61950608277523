import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import classNames from 'classnames';
import styles from './styles';
import { CardMedia } from '@mui/material';
import ButtonComponent from '../../Button';

const DescriptionImageModalDialog = ({
  image,
  isOpen,
  onClose,
  dialogClass,
  isFullScreen,
}) => {
  const classes = styles();

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      className={classNames(dialogClass, classes.dialogModal)}
      fullWidth={isFullScreen}
      maxWidth={'xl'}
    >
        <DialogContent className={classes.dialogContent} dividers>
        <CardMedia
          component="img"
          image={image}
          alt={image}
          sx={{ objectFit: 'contain', width: 350, height: 350 }}
        />
        </DialogContent>
        <DialogActions>
        <ButtonComponent
        label='Close'
        variant={'contained'}
        size={'small'}
        onClick={onClose}
        />
        </DialogActions>
    </Dialog>
  );
};

DescriptionImageModalDialog.propTypes = {
  image: PropTypes.string.isRequired,
  isOpen: PropTypes.string,
  onClose: PropTypes.func,
  dialogClass: PropTypes.string,
  isFullScreen: PropTypes.bool,
};

DescriptionImageModalDialog.defaultProps = {
  image: null,
  isOpen: '',
  onClose: null,
  dialogClass: null,
  isFullScreen: false,
};

export default DescriptionImageModalDialog;
