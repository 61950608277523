const columns = (renderCell, renderSignalCell, renderActions) => [
  {
    field: 'signal',
    headerName: 'Signal',
    renderEditCell: renderSignalCell,
    editable: true,
    width: 300,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'lowLow',
    headerName: 'Low-Low',
    width: 120,
    hide: false,
    editable: true,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'low',
    headerName: 'Low',
    width: 120,
    hide: false,
    editable: true,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'high',
    headerName: 'High',
    width: 120,
    hide: false,
    editable: true,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'highHigh',
    headerName: 'High-High',
    width: 120,
    hide: false,
    editable: true,
    renderCell,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'message',
    headerName: 'Alerts',
    width: 120,
    hide: true,
    editable: true,
    renderCell,
    headerAlign: 'center',
  },
  {
    field: 'recipients',
    headerName: 'Recipients',
    width: 300,
    hide: false,
    editable: true,
    renderCell,
    headerAlign: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 120,
    hide: false,
    headerAlign: 'center',
    renderCell: renderActions,
  },
];

export default columns;
