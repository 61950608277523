/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography, Box, CardMedia, Paper } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import {
  ShoppingCartCheckoutRounded,
} from '@mui/icons-material';
import styles from '../styles';
import SliderOrderConfirmModalDialog from '../../../slideOrderQuoteConfirmationModal';
import {
  postOrderParts,
  listOrders,
} from '../../../../../store/actions/orders';
import OrderProcessingModalDialog from '../../../../ui/OrderProcessingModalDialog';
import { useAuth } from '../../../../../hooks/useAuth';

const SplitMobileOrders = ({
  cartItems,
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { orderPartsPostSuccess } = useSelector(
    (state) => state.orders
  );
  const [cart, setCart] = useState(cartItems);
  const [cartPrice, setCartPrice] = useState(0);
  const apiRef = useGridApiRef([]);
  const [sliderModal, setSliderModal] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const classes = styles();

  useEffect(() => {
    if (orderPartsPostSuccess) {
      setIsProcessing(false);
    }
  }, [orderPartsPostSuccess]);

  useEffect(() => {
    setCart(cartItems);
    const cartTotalPrice = cartItems
      ?.map((i) => +i?.Price)
      .reduce((sum, num) => sum + num, 0);
    setCartPrice(cartTotalPrice.toFixed(2));
  }, [cartItems]);
  const handleOrderButtonClick = () => {
    setSliderModal(true);
    // onActionClick(e);
  };
  const placeOrder = () => {
    dispatch(postOrderParts(user, cart));
    setSliderModal(false);
    setIsProcessing(true);
    setLoadingDialog(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialog(false);
    dispatch(listOrders(user));
  };

  const CartCard = ({ data }) => {
    const keysToShow = ['Name', 'Size', 'Price', 'Description', 'Vendor'];
    return (
      <Paper key={data._id} elevation={3} sx={{ backgroundColor: '#f2f2f2', padding: 4, margin: '14px', position: 'relative' }}>
        {Object.entries(data).map(([key, value]) => {
          if (keysToShow.includes(key)) {
            return (
              <Box key={key}>
                <Typography variant='h6' sx={{ textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '10px' }}>{key}</Typography>
                <Typography variant='h6' sx={{ marginBottom: '14px', color: '#666' }}>{value}</Typography>
              </Box>
            );
          }
          return null;
        })}
        <CardMedia
          component="img"
          image={data.Image}
          alt={data.Name}
          sx={{ objectFit: 'contain', height: 'auto', width: '50%', position: 'absolute', top: 4, right: 4 }}
        />
      </Paper>
    );
  };

  if (apiRef.current === null) apiRef.current = {};

  return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid sx={{ padding: '0 14px' }} item container alignItems="center" justifyContent="space-between">
            <Box className={classes.displayQuants}>
            {`Quantity: ${cart?.length}, Total Price: $${cartPrice}`}
            </Box>
            <Box>
            <IconButton
              onClick={() => {
                handleOrderButtonClick();
              }}
            >
              <ShoppingCartCheckoutRounded fontSize='large'/>
            </IconButton>
            </Box>
          </Grid>
          </Grid>
          <Grid>
            {cart?.map((item) => {
              return <CartCard key={item?.rowId} data={item} />;
            })};
          </Grid>
        <OrderProcessingModalDialog
          isOpen={loadingDialog}
          isProcessing={isProcessing}
          closeLoadingDialog={closeLoadingDialog}
          />
        <SliderOrderConfirmModalDialog
            title={'Order'}
            placeOrder={placeOrder}
            isOpen={sliderModal}
            setSliderModal={setSliderModal}
          />
      </>
  );
};
SplitMobileOrders.propTypes = {
  cartItems: PropTypes.any,
};
export default SplitMobileOrders;
