import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => {
  return {
    toolBar: {
      background: 'linear-gradient(to right, #ffffff, #cacaca)',
      fontSize: '12px',
    },
    fixedAppbar: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      maxWidth: '1280px',
      padding: '0px 24px',
      margin: '0 auto',
      zIndex: 1000
    }
  };
});

export default styles;
