import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  logo: {
    width: '300px',
  },
  divider: {
    marginTop: '10px',
    marginBottom: '20px',
    width: '100%',
  },
  text: {
    marginBottom: '10px',
  },
  list: {
    listStyleType: 'disc',
    marginLeft: '20px',
    marginBottom: '10px',
  },
  listItem: { display: 'list-item' },
}));

export default styles;
