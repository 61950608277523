import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import {
  ShoppingCartCheckoutRounded,
} from '@mui/icons-material';
import { renderCell } from '../../Datagrid/table_config';
import { useAuth } from '../../../../hooks/useAuth';
import styles from '../styles';
import Datagrid from '../../Datagrid';
import DescriptionImageModalDialog from '../../DescriptionImageModalDialog';
import columns from './table_config';
import SliderOrderConfirmModalDialog from '../../SliderOrderConfirmModalDialog';
import { listOrders, postOrderParts } from '../../../../store/actions/orders';
import OrderProcessingModalDialog from '../../OrderProcessingModalDialog';

const SplitOrdersCart = ({
  cartItems,
  lastOrder,
  onCloseClick
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { loading, orderPartsPostSuccess } = useSelector(
    (state) => state.orders
  );
  const [cart, setCart] = useState(cartItems);
  const [cartPrice, setCartPrice] = useState(0);
  const apiRef = useGridApiRef([]);
  const [partImage, setPartImage] = useState(null);
  const [sliderModal, setSliderModal] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const classes = styles();

  useEffect(() => {
    if (orderPartsPostSuccess) {
      setIsProcessing(false);
    }
  }, [orderPartsPostSuccess]);

  useEffect(() => {
    setCart(cartItems);
    const cartTotalPrice = cartItems
      ?.map((i) => +i?.Price)
      .reduce((sum, num) => sum + num, 0);
    setCartPrice(cartTotalPrice.toFixed(2));
  }, [cartItems]);

  const handleOrderButtonClick = () => {
    setSliderModal(true);
    // onActionClick(e);
  };

  const placeOrder = () => {
    dispatch(postOrderParts(user, cart));
    setSliderModal(false);
    setIsProcessing(true);
    setLoadingDialog(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialog(false);
    dispatch(listOrders(user));
    if (lastOrder) {
      onCloseClick();
    }
  };

  const renderDescriptionCell = ({ value, row }) => (
    <Typography onClick={() => setPartImage(row?.Image)} style={{ fontSize: '12px', fontWeight: 'normal', textWrap: 'wrap', cursor: 'pointer' }}>{value}</Typography>
  );

  if (apiRef.current === null) apiRef.current = {};

  return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item className={classes.displayQuants}>
            {`Quantity: ${cart?.length}, Total Price: $${cartPrice}`}
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                handleOrderButtonClick();
              }}
            >
              <ShoppingCartCheckoutRounded fontSize='large'/>
            </IconButton>
          </Grid>
          </Grid>
          <Datagrid
            loading={loading}
            disableStickyHeader={false}
            data={cartItems}
            columns={columns(
              renderCell,
              user.type,
              renderDescriptionCell
            )}
            apiRef={apiRef}
            getRowId={(row) => `${Math.random(1000)}_${row._id}`}
            getRowHeight={() => 'auto'}
            sx={{
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold !important',
              },
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
            height={'auto'}
            pageSize={100}
            toolbar={{
              options: {
                columns: false,
                filters: true,
                density: false,
                export: false,
              },
            }}
          />
        {partImage &&
          <DescriptionImageModalDialog
          image={partImage}
          isOpen={partImage}
          onClose={() => setPartImage(null)}
          />
        }
        <OrderProcessingModalDialog
          isOpen={loadingDialog}
          isProcessing={isProcessing}
          closeLoadingDialog={closeLoadingDialog}
          />
        <SliderOrderConfirmModalDialog
            title={'Order'}
            placeOrder={placeOrder}
            isOpen={sliderModal}
            setSliderModal={setSliderModal}
          />
      </>
  );
};

SplitOrdersCart.propTypes = {
  cartItems: PropTypes.any,
  lastOrder: PropTypes.bool,
  onCloseClick: PropTypes.func
};

export default SplitOrdersCart;
