import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import styles from './styles';

const SelectAutoComplete = (props) => {
  const { id, field, data, updateData, closeCellAfterSelect } = props;
  const apiRef = useGridApiContext();
  const classes = styles();
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    const initialValue = data.find((option) => option._id === id) || null;
    setSelectedValue(initialValue);
  }, [id, data]);

  const handleChange = (event, newValue) => {
    event.stopPropagation();
    event.preventDefault();
    if (typeof newValue === 'string') {
      const newEntry = { LeaseName: newValue, WellNumber: '', WellID: '' };
      updateData(id, field, newEntry);
      setSelectedValue(newEntry);
      setInputValue(newValue);
    } else if (newValue) {
      updateData(id, field, newValue);
      setSelectedValue(newValue);
      setInputValue(newValue.LeaseName);
    }

    if (closeCellAfterSelect) {
      apiRef.current.stopCellEditMode({ id, field });
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      const newEntry = { LeaseName: inputValue, WellNumber: '', WellID: '' };
      updateData(id, field, newEntry);
      setSelectedValue(newEntry);
      if (closeCellAfterSelect) {
        apiRef.current.stopCellEditMode({ id, field });
      }
    }
  };

  const handleBlur = () => {
    const newEntry = { LeaseName: inputValue, WellNumber: '', WellID: '' };
    updateData(id, field, newEntry);
    if (closeCellAfterSelect) {
      apiRef.current.stopCellEditMode({ id, field });
    }
    setSelectedValue(newEntry);
  };

  const sortedData = data.sort((a, b) => a.LeaseName.localeCompare(b.LeaseName));

  return (
    <FormControl
      variant="filled"
      id="controlled-form"
      className={classes.inputField}
      sx={{ bgcolor: 'transparent' }}
    >
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={sortedData}
        value={selectedValue}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return `${option?.LeaseName} ${option?.WellNumber}`;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select or Enter"
            variant="standard"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        )}
      />
    </FormControl>
  );
};

SelectAutoComplete.propTypes = {
  field: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  data: PropTypes.array.isRequired,
  updateData: PropTypes.func.isRequired,
  closeCellAfterSelect: PropTypes.bool,
};

export default SelectAutoComplete;
