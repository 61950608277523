/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Grid, Typography, IconButton, Box } from '@mui/material';
import { CancelPresentationRounded, DeleteRounded, SaveRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';
import styles from './style';
import { csvFileToArray } from '../../../utils/csvFileToArray';
import Datagrid from '../../ui/Datagrid';
import columns from './table_config';
import { renderCell } from '../../ui/Datagrid/table_config';
import ModalDialog from '../../ui/ModalDialog';
import { createDrillStringDetailsProperty, deleteDrillStringDetailsProperty, listDrillStringDetails, updateDrillStringDetails, updateDrillStringDetailsProperty } from '../../../store/actions/wellsDrillString';
import { enqueueSnackbar } from '../../../store/actions/snackbar';
import { WELLS_DRILL_STRING_VALIDATION } from '../../../utils/constants';
import SelectEditInputTextField from '../../ui/SelectEditInputTextField';

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

const DrillStringDetails = ({
  handleClose,
  data,
  open,
  drillStringData
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const apiRef = useGridApiRef();
  const drillString = useSelector((state) => state.wellsDrillString);
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});
  const fileReader = new FileReader();

  useEffect(() => {
    if (!drillString.details.loading) {
      setProperties(drillString.details.data);
    }
    return () => {
      setProperties([]);
    };
  }, [drillString.details]);

  useEffect(() => {
    if (properties[0]?.name === '') {
      const id = properties[0]?._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 1
        });
        apiRef.current.setCellMode(id, 'name', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'name');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties.length]);

  const handleAddProperty = () => {
    const currentProperties = [...properties];
    const nextId = currentProperties.length - 1;
    const newRow = {
      name: '',
      od: '',
      id: '',
      uw: '',
      tj_od: '',
      tj_id: '',
      len: '',
      'tj_len%': '',
      grid_id: nextId + 1,
      _id: generateRandom(),
      isNewRow: true
    };
    currentProperties.unshift(newRow);
    setProperties(currentProperties);
  };

  const handleImportedData = (csvData) => {
    const newAssetTypes = csvData.map((item) => {
      return {
        ...item,
        _id: generateRandom(),
      };
    });
    if (newAssetTypes) {
      setProperties(newAssetTypes);
    //   dispatch(updateAssetTypeDetails({ ...data, details: newAssetTypes }));
    }
  };

  const checkDrillStringValues = (value) => {
    const row = value;
    const invalidFields = [];
    if (!row?.name) invalidFields.push('Name');
    if (row?.od === undefined || row?.od === null || row?.od === '') invalidFields.push('OD');
    if (row?.id === undefined || row?.id === null || row?.id === '') invalidFields.push('ID');
    if (row?.uw === undefined || row?.uw === '') invalidFields.push('Unit Weight');
    if (row?.tj_od === undefined || row?.tj_od === '') invalidFields.push('Tooljoint OD');
    if (row?.tj_id === undefined || row?.tj_id === '') invalidFields.push('Tooljoint ID');
    if (row?.len === undefined || row?.len === '') invalidFields.push('Length');
    if (row['tj_len%'] === undefined || row['tj_len%'] === '') invalidFields.push('Tooljoint Length %');

    if (invalidFields.length === 0) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      return true;
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          `Please enter the following details: ${invalidFields.join(', ')}`,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const validateProperties = (row) => {
    const { name, od, id, uw, tj_od, tj_id, len, 'tj_len%': tj_len } = row;
    if (typeof name !== 'string' || name.length > 100) return WELLS_DRILL_STRING_VALIDATION.name;
    if (typeof od !== 'number' || od <= id) return WELLS_DRILL_STRING_VALIDATION.od;
    if (typeof id !== 'number' || id < 1.0) return WELLS_DRILL_STRING_VALIDATION.id;
    if (typeof uw !== 'number' || uw < 0.0) return WELLS_DRILL_STRING_VALIDATION.uw;
    if (typeof tj_od !== 'number' || tj_od < 0.0) return WELLS_DRILL_STRING_VALIDATION.tj_od;
    if (typeof tj_id !== 'number' || tj_id < 0.0) return WELLS_DRILL_STRING_VALIDATION.tj_id;
    if (typeof len !== 'number' || len < 0.0) return WELLS_DRILL_STRING_VALIDATION.len;
    if (typeof tj_len !== 'number' || tj_len < 0 || tj_len > 15) return WELLS_DRILL_STRING_VALIDATION.tj_len;

    if (tj_len !== 0) {
      if (tj_id > id) return WELLS_DRILL_STRING_VALIDATION.tj_len_non_zero_tj_id;
      if (tj_od < od) return WELLS_DRILL_STRING_VALIDATION.tj_len_non_zero_tj_od;
      if (tj_id < 1.0) return WELLS_DRILL_STRING_VALIDATION.tj_len_min_tj_id;
    }

    return '';
  };

  const saveProperty = (row) => {
    if (checkDrillStringValues(row)) {
      for (const key in row) {
        if (typeof row[key] === 'string' && !isNaN(row[key])) {
          row[key] = Number(row[key]);
        }
      }
      const validationError = validateProperties(row);
      if (validationError === '') {
        const body = {
          name: row.name,
          od: row.od,
          id: row.id,
          uw: row.uw,
          tj_od: row.tj_od,
          tj_id: row.tj_id,
          len: row.len,
          'tj_len%': row['tj_len%'],
        };
        if (row?.isNewRow) {
          dispatch(createDrillStringDetailsProperty(data?._id ? data?._id : drillString.drillStringId, body, data.wellsInfoId));
        } else {
          dispatch(updateDrillStringDetailsProperty(data?._id ? data?._id : drillString.drillStringId, row?._id, body, data.wellsInfoId));
        }
        const newProperties = properties.map((item) => item._id === row._id ? row : item);
        const updatedData = {
          ...data,
          _id: drillString?.drillStringId ? drillString?.drillStringId : data?._id,
          drillstring_details: newProperties
        };
        dispatch(updateDrillStringDetails(updatedData));
      } else {
        if (validationError.length) setInvalidRows(prev => ({ ...prev, [row._id]: true }));
        dispatch(enqueueSnackbar(validationError, 'error', new Date().getTime() + Math.random()));
      }
    }
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: typeof value === 'string' ? value : Number(value)
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const uploadCSVFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        const importedAssetTypes = csvFileToArray(csvOutput, 'preserveType');
        handleImportedData(importedAssetTypes);
      };
      fileReader.readAsText(file);
    }
  };

  const handleDeleteDrillString = (row) => {
    const filteredProperties = properties.filter((item) => item._id !== row._id);
    const updatedDrillStringData = {
      ...data,
      _id: drillString?.drillStringId ? drillString?.drillStringId : data?._id,
      drillstring_details: filteredProperties
    };
    dispatch(updateDrillStringDetails(updatedDrillStringData));
    setProperties(filteredProperties);
    if (!row?.isNewRow) {
      dispatch(deleteDrillStringDetailsProperty(data?._id ? data?._id : drillString.drillStringId, row?._id, data.wellsInfoId));
    } else {
      dispatch(listDrillStringDetails(data?._id ? data?._id : drillString.drillStringId));
    }
  };

  const getModalTitle = () => {
    return (
      <>
       <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>{drillStringData?.row?.name}, {data.MD} {'(ft)'}</Grid>
          <Grid item>
            <IconButton
              onClick={handleClose}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField numberFormatOnly {...params} updateData={updateData} />;
  };

  const renderActions = ({ row }) => {
    return (
    <Grid container justifyContent="center">
      <IconButton
        variant="solid"
        fontSize="large"
        disabled={drillString.details.loading}
        onClick={() => {
          saveProperty(row);
        }}
      >
        <SaveRounded />
      </IconButton>
      <IconButton
        disabled={drillString.details.loading}
        variant="solid"
        onClick={() => {
          handleDeleteDrillString(row);
        }}
      >
        <DeleteRounded />
      </IconButton>
    </Grid>
    );
  };

  return (
    <ModalDialog
      title={getModalTitle()}
      isOpen={open}
      onClose={handleClose}
      maxWidth='lg'
      dialogClass={classes.dialogModal}
    >
      <Grid container>
      <Grid item className={classes.customTool} xs={1} justifyContent="flex-start" >
            <Box>
              <Typography variant='button' fontWeight='700' className={drillString.loading || properties[0]?.isNewRow ? classes.disableIcon : ''} onClick={handleAddProperty}>+ Add</Typography>
            </Box>
            <Box>
              <label className={classes.importLabel} htmlFor='getCSVFile'>Import</label>
              <input type='file' id='getCSVFile' accept={'.csv'} style={{ display: 'none' }}
              onChange={uploadCSVFile}
              />
            </Box>
          </Grid>
        <Grid item xs={12}>
          <Datagrid
            apiRef={apiRef}
            data={properties}
            loading={drillString.details.loading}
            getRowId={(row) => row._id}
            columns={columns(renderCell, renderActions, renderValueCell)}
            disableStickyHeader={false}
            autoHeight={false}
            height={'50vh'}
            sx={{
              marginLeft: '-6px',
              '.MuiDataGrid-cell': {
                fontSize: '1rem',
                fontWeight: 900
              },
              '.MuiDataGrid-columnHeader': {
                fontSize: '1.2rem',
                fontWeight: '900 !important'
              }
            }}
            pageSize={100}
            editMode="cell"
            toolbar={{
              options: {
                columns: false,
                filters: false,
                density: false,
                export: false
              },
            }}
            onCellEditCommit={({ id, value, field }) => {
              updateData(id, field, value);
            }}
            getRowClassName={(params) => {
              return invalidRows[params.id] ? classes.invalidRow : '';
            }}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
};
DrillStringDetails.propTypes = {
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  open: PropTypes.bool.isRequired,
  drillStringData: PropTypes.any
};
DrillStringDetails.defaultProps = {
  data: {}
};
export default DrillStringDetails;
