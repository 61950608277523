/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../../ui/ModalDialog';
import { Box, CardMedia, Grid, IconButton, Paper, Typography } from '@mui/material';
import { DeleteRounded, ContentCopy, PrintRounded, CancelPresentationRounded, ShoppingCartCheckoutRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../hooks/useAuth';
import { listQuoteParts, postQuoteParts, listQuotes, downloadQuotePDF, removeFromGrayList, addToGrayList } from '../../../../store/actions/quotes';
import { isFullArray } from '../../../../utils/validators';
import OrderProcessingModalDialog from '../../../ui/OrderProcessingModalDialog';
import { cartDialogSort } from '../../../../utils/cartDialogSort';
import { isMobile } from '../../../../utils/isMobile';
import SliderOrderConfirmModalDialog from '../../slideOrderQuoteConfirmationModal';

const QuotesCartMobileDialog = ({
  isOpen,
  partIDs,
  quoteData,
  onCloseClick,
  onActionClick,
  saveButtonText,
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { loading, grayPartsDetails, quotePartsPostSuccess } = useSelector(
    (state) => state.quotes
  );
  const [cart, setCart] = useState([]);
  const [cartPrice, setCartPrice] = useState(0);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(null);
  const quotes = useSelector((state) => state.quotes);
  const mobile = isMobile();
  const [sliderModal, setSliderModal] = useState(false);

  const handleDownloadQuotePDF = (quoteId) => {
    dispatch(downloadQuotePDF(quoteId));
  };

  useEffect(() => {
    if (quoteData && Object.keys(quoteData)?.length) {
      setDialogTitle(getDialougeTitleWithPrint(quoteData));
    }
  }, [quotes.printState]);

  const getDialougeTitleWithPrint = ({ _id: quoteId, timestamp, quantity, price }) => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            { `Date: ${timestamp}, Quote Id: ${quoteId}, Quantity: ${quantity}, Total Price: $${price.toFixed(2)}`}
          </Grid>
          <Grid item>
            <IconButton
              variant="solid"
              onClick={() => {
                handleDownloadQuotePDF(quoteId);
              }}
              disabled={quotes.printState === 'loading'}
            >
              <PrintRounded fontSize='large' />
            </IconButton>
            <IconButton
              onClick={() => {
                handleQuoteButtonClick();
              }}
            >
              <ShoppingCartCheckoutRounded fontSize='large'/>
            </IconButton>
            <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(listQuoteParts(user.companyId, partIDs));
      if (saveButtonText) {
        setDialogTitle(getDialougeTitleWithPrint(quoteData));
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (quotePartsPostSuccess) {
      onCloseClick();
      setIsProcessing(false);
    }
  }, [quotePartsPostSuccess]);

  useEffect(() => {
    if (isFullArray(grayPartsDetails)) {
      const cartDetails = partIDs
        .map((id) => grayPartsDetails.find((part) => part._id === id))
        .filter((part) => !!part)
        .map((part) => ({ ...part, rowId: `${Math.random(1000)}_${part._id}` }));
      const sortedCart = cartDetails.sort(cartDialogSort);
      setCart(sortedCart);
      const cartTotalPrice = cartDetails
        ?.map((i) => +i?.Price)
        .reduce((sum, num) => sum + num, 0);
      setCartPrice(cartTotalPrice.toFixed(2));
    }
  }, [grayPartsDetails]);

  const handleQuoteButtonClick = (e) => {
    setSliderModal(true);
    onActionClick(e);
  };

  const placeOrder = () => {
    dispatch(postQuoteParts(user, cart));
    setSliderModal(false);
    setIsProcessing(true);
    setLoadingDialog(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialog(false);
    onCloseClick();
    dispatch(listQuotes(user));
  };

  const getModalTitle = () => {
    const cartQuantity = cart?.length;
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {`Quantity: ${cartQuantity}, Total Price: $${cartPrice}`}
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                handleQuoteButtonClick();
              }}
            >
              <ShoppingCartCheckoutRounded fontSize={mobile ? 'medium' : 'large'} />
            </IconButton>
            <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize={mobile ? 'medium' : 'large'} />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const createDialogTitle = (quoteData, cart, cartPrice) => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
          {`Date: ${quoteData.timestamp}, Order Id: ${quoteData._id}, Quantity: ${cart.length}, Total Price: $${cartPrice}`}
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                handleQuoteButtonClick();
              }}
            >
              <ShoppingCartCheckoutRounded fontSize={mobile ? 'medium' : 'large'} />
            </IconButton>
            <IconButton
              onClick={() => {
                onCloseClick();
              }}
            >
              <CancelPresentationRounded fontSize={mobile ? 'medium' : 'large'} />
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const removeRow = (row) => {
    const newCart = [...cart];
    const filtered = newCart.filter(item => item.rowId !== row?.rowId);
    setCart(filtered);
    const cartTotalPrice = filtered
      ?.map((i) => +i?.Price)
      .reduce((sum, num) => sum + num, 0);
    const sortedCart = filtered.sort(cartDialogSort);
    setCart(sortedCart);
    setCartPrice(cartTotalPrice.toFixed(2));
    dispatch(removeFromGrayList(row._id));
    if (saveButtonText) {
      setDialogTitle(createDialogTitle(quoteData, filtered, cartTotalPrice.toFixed(2)));
    }
    if (sortedCart.length === 0) {
      onCloseClick();
    }
  };

  const duplicateRow = (row) => {
    const newCart = [...cart, { ...row, rowId: `${Math.random(1000)}_${row._id}` }];
    const cartTotalPrice = newCart
      ?.map((i) => +i?.Price)
      .reduce((sum, num) => sum + num, 0);
    const sortedCart = newCart.sort(cartDialogSort);
    setCart(sortedCart);
    dispatch(addToGrayList(row._id));
    setCartPrice(cartTotalPrice.toFixed(2));
    if (saveButtonText) {
      setDialogTitle(createDialogTitle(quoteData, newCart, cartTotalPrice.toFixed(2)));
    }
  };

  const renderActions = ({
    id,
    row,
    flexEnd
  }) => {
    return (
      <Grid container justifyContent={flexEnd ? 'flex-end' : 'center'} sx={{ marginTop: flexEnd && '14px' }}>
        <IconButton
        variant="solid"
        onClick={() => removeRow(row, id)}
      >
        <DeleteRounded sx={{ fontSize: flexEnd && '30px' }}/>
      </IconButton>
        <IconButton
          variant="solid"
          onClick={() => duplicateRow(row, id)}
        >
          <ContentCopy sx={{ fontSize: flexEnd && '30px' }}/>
        </IconButton>
      </Grid>
    );
  };

  const CartCard = ({ data }) => {
    const keysToShow = ['Name', 'Size', 'Price', 'Description', 'Vendor'];
    return (
      <Paper key={data._id} elevation={3} sx={{ backgroundColor: '#f2f2f2', padding: 4, margin: '14px', position: 'relative' }}>
        {Object.entries(data).map(([key, value]) => {
          if (keysToShow.includes(key)) {
            return (
              <Box key={key}>
                <Typography variant='h6' sx={{ textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '10px' }}>{key}</Typography>
                <Typography variant='h6' sx={{ marginBottom: '14px', color: '#666' }}>{value}</Typography>
              </Box>
            );
          }
          return null;
        })}
        {renderActions({ id: data._id, row: data, flexEnd: true })}
        <CardMedia
          component="img"
          image={data.Image}
          alt={data.Name}
          sx={{ objectFit: 'contain', height: 'auto', width: '50%', position: 'absolute', top: 4, right: 4 }}
        />
      </Paper>
    );
  };

  return (
    !loading &&
    isFullArray(cart) && (
      <>
        <ModalDialog
          title={dialogTitle || getModalTitle()}
          isOpen={isOpen}
          onClose={onCloseClick}
        >
          <Grid>
            {cart?.map((item) => {
              return <CartCard key={item?.rowId} data={item} />;
            })};
          </Grid>
        </ModalDialog>
        <OrderProcessingModalDialog
          isOpen={loadingDialog}
          isProcessing={isProcessing}
          closeLoadingDialog={closeLoadingDialog}
        />
        <SliderOrderConfirmModalDialog
            title={'Quote'}
            placeOrder={placeOrder}
            isOpen={sliderModal}
            setSliderModal={setSliderModal}
          />
      </>
    )
  );
};

QuotesCartMobileDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  partIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  quoteData: PropTypes.any,
  onCloseClick: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
  saveButtonText: PropTypes.string,
};

export default QuotesCartMobileDialog;
