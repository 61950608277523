import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles';
import { listJobs } from '../../store/actions/jobs';
import { createActuatorReport } from '../../store/actions/actuatorReport';
import columns from './table_config';
import AlarmsModalDialog from '../ui/AlarmsModalDialog';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import Datagrid from '../ui/Datagrid';
import { renderCell } from '../ui/Datagrid/table_config';
import {
  AnalyticsRounded,
  NotificationsNoneRounded,
  SettingsSuggestRounded,
  WidgetsRounded,
  RoomRounded,
  SummarizeRounded,
} from '@mui/icons-material';
import AssignAssetsModalDialog from '../ui/AssignAssetsModalDialog';
import JobStatusModalDialog from '../ui/JobStatusModalDialog';
import FilterCarousel from '../filterCarousel';
import FilterCarouselItem from '../filterCarouselItem';
import ReportCarouselItem from '../reportCarouselItem';
import CustomModal from '../customModal';
import {
  ACTUATOR_REPORT,
  DRILLING_FILTERS,
  JOBS_SYSTEM_STATUS,
} from '../../utils/constants';
import { ASSET_DATA_TYPES, REDUX_ACTIONS } from '../../shared/config/constants';

const JobsGrid = (props) => {
  const classes = styles();
  const jobs = useSelector((state) => state.jobs);
  const dispatch = useDispatch();

  const [isAssetsDialogOpen, setIsAssetsDialogOpen] = useState(false);
  const [isJobStatusDialogOpen, setIsJobStatusDialogOpen] = useState(false);
  const [currentJob, setCurrentJob] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [drillingLink, setDrillingLink] = useState('');
  const [alarmModalOpen, setAlarmModalOpen] = useState(false);

  // Report Handlign
  const [reportJobId, setReportJobId] = useState('');
  const [reportStartDate, setReportStartDate] = useState('');
  const [reportEndDate, setReportEndDate] = useState('');

  useEffect(() => {
    if (!jobs.loading && jobs.data.length === 0) {
      dispatch(listJobs());
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(listJobs());
    }, 12000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const showAssetsDialog = (job) => {
    setCurrentJob(job);
    setIsAssetsDialogOpen(true);
  };

  const showJobStatusDialog = (job) => {
    setCurrentJob(job);
    setIsJobStatusDialogOpen(true);
  };

  const handleNavigate = (assetDataType, jobId, rigName) => {
    let pageTitle = '';

    if (rigName) {
      pageTitle = `&pageTitle=${encodeURIComponent(rigName)}`;
    }
    window.open(
      `/Data?assetDataType=${assetDataType}_${jobId}${pageTitle}`,
      '_blank'
    );
  };

  const drillingModal = (assetDataType, jobId, rigName) => {
    let pageTitle = '';

    if (rigName) {
      pageTitle = `&pageTitle=${encodeURIComponent(rigName)}`;
    }
    setDrillingLink(
      `/Data?assetDataType=${assetDataType}_${jobId}${pageTitle}`
    );
    setShowModal(true);
  };

  const hideDrillingModal = () => {
    setDrillingLink('');
    setShowModal(false);
  };

  const handleCardItemClick = (cardIndex) => {
    setShowModal(false);
    setDrillingLink(drillingLink);
    window.open(
      drillingLink + `&filterId=${DRILLING_FILTERS[cardIndex].id}`,
      '_blank'
    );
  };

  const reportModal = (jobId) => {
    setReportJobId(jobId);
    setShowReportModal(true);
  };

  const hideReportModal = () => {
    setReportJobId('');
    setReportStartDate('');
    setReportEndDate('');
    setShowReportModal(false);
  };

  const handleReportCardItemClick = (_cardIndex) => {
    // todo add report types
  };

  const handleReportDateChange = (startDate, endDate) => {
    setReportStartDate(startDate);
    setReportEndDate(endDate);
  };

  const createReport = () => {
    if (reportJobId !== '' && reportStartDate !== '' && reportEndDate !== '') {
      dispatch(
        createActuatorReport(reportStartDate, reportEndDate, reportJobId)
      );
    }
  };

  const getPinColor = (status) => {
    switch (status) {
      case JOBS_SYSTEM_STATUS.NONE:
        return 'green';
      case JOBS_SYSTEM_STATUS.FAULTS_ONLY:
      case JOBS_SYSTEM_STATUS.BOTH:
        return 'red';
      case JOBS_SYSTEM_STATUS.WARNINGS_ONLY:
        return 'amber';
      default:
        return 'default';
    }
  };

  const handleAlarmsModal = (overallStatus) => {
    if (overallStatus > 0) {
      setAlarmModalOpen(true);
    }
  };

  const renderDataCell = (props) => {
    const data = props.row.data;
    const jobId = props.row._id;
    const rigName = props.row.Rig;
    return (
      <Grid container justifyContent="center">
        {
          /* prettier-ignore */
          data === 1
            ? (
                  <IconButton
                    variant="solid"
                    key="drill_data"
                    onClick={() => {
                      drillingModal(ASSET_DATA_TYPES.DRILLINGDATA, jobId, rigName);
                    }
                    }
                  >
                    <AnalyticsRounded />
                  </IconButton>
              )
            : data === 2
              ? (
                    <IconButton
                      key="actuator_data"
                      variant="solid"
                      onClick={() => {
                        handleNavigate(ASSET_DATA_TYPES.ACTUATORDATA, jobId, rigName);
                      }
                      }
                    >
                      <SettingsSuggestRounded />
                    </IconButton>
                )
              : data === 3
                ? (
                      <>
                        <IconButton
                          key="actuator_data"
                          variant="solid"
                          onClick={() => {
                            handleNavigate(ASSET_DATA_TYPES.ACTUATORDATA, jobId, rigName);
                          }

                          }
                        >
                          <SettingsSuggestRounded />
                        </IconButton>
                        <IconButton
                          variant="solid"
                          key="drill_data"
                          onClick={() => {
                            drillingModal(ASSET_DATA_TYPES.DRILLINGDATA, jobId, rigName);
                          }

                          }
                        >
                          <AnalyticsRounded />
                        </IconButton>
                      </>
                  )
                : null
        }
      </Grid>
    );
  };

  const renderReportsCell = (_props) => {
    const data = _props.row.data;
    const jobId = _props.row._id;
    return (
      <Grid container justifyContent="center">
        {
          /* prettier-ignore */
          data === 2
            ? (
                  <IconButton
                    key="actuator_data"
                    variant="solid"
                    onClick={() => {
                      reportModal(jobId);
                    }}
                  >
                    <SummarizeRounded />
                  </IconButton>
              )
            : data === 3
              ? (
                    <IconButton
                      key="actuator_data"
                      variant="solid"
                      onClick={() => {
                        reportModal(jobId);
                      }}
                    >
                      <SummarizeRounded />
                    </IconButton>
                )
              : null
        }
      </Grid>
    );
  };

  const renderAssetsCell = (props) => (
    <Grid container justifyContent="center">
      <IconButton
        variant="solid"
        onClick={(_evt) => {
          showAssetsDialog(props.row);
        }}
      >
        <WidgetsRounded />
      </IconButton>
    </Grid>
  );
  const renderStatusCell = (_props) => {
    const status = _props.row.systemStatus;
    const pinColor = getPinColor(status);

    const noActionPin = [
      JOBS_SYSTEM_STATUS.NO_ACTUATOR_DATA,
      JOBS_SYSTEM_STATUS.NONE,
    ].includes(status);

    return (
      pinColor && (
        <Grid container justifyContent="center">
          <IconButton
            className={noActionPin ? classes.noActionPin : ''}
            key="systemStatus"
            variant="solid"
            onClick={({ row: job }) => {
              if (noActionPin) {
                showJobStatusDialog(job);
              }
            }}
          >
            <RoomRounded color={`${pinColor}Status`} />
          </IconButton>
        </Grid>
      )
    );
  };

  const renderAlarmsCell = (_props) => {
    const data = _props.row.data;
    return (
      <Grid container justifyContent="center">
        {
          /* prettier-ignore */
          data === 2
            ? (
                  <IconButton key="actuator_data" variant="solid" onClick={() => {
                    handleAlarmsModal(_props.row.alarms.overallStatus);
                  }}>
                    <NotificationsNoneRounded color={`alarm${_props.row.alarms.overallStatus}`} />
                  </IconButton>
              )
            : data === 3
              ? (
                    <IconButton key="actuator_data" variant="solid" onClick={() => {
                      handleAlarmsModal(_props.row.alarms.overallStatus);
                    }}>
                      <NotificationsNoneRounded color={`alarm${_props.row.alarms.overallStatus}`} />
                    </IconButton>
                )
              : null
        }
      </Grid>
    );
  };

  return (
    <>
      <Datagrid
        data={jobs.data}
        columns={columns(
          false,
          renderCell,
          renderDataCell,
          renderReportsCell,
          renderAssetsCell,
          renderStatusCell,
          renderAlarmsCell
        )}
        sx={{
          '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold !important',
          },
          '.MuiDataGrid-cell': {
            fontSize: 'medium',
            fontWeight: 'bold !important'
          },
        }}
        loading={jobs.loading}
        onRowClick={({ row: job }) => {
          setCurrentJob(job);
          dispatch({
            type: REDUX_ACTIONS.SET_CURRENT_JOB,
            payload: {
              jobId: job._id,
            },
          });
        }}
        onRowDoubleClick={({ row: job }) => {
          showAssetsDialog(job);
        }}
        toolbar={jobs.data.length > 0 && {
          options: {
            columns: true,
            filters: true,
            density: true,
            export: false,
          },
        }}
        {...props}
      />
      <AlarmsModalDialog
        isOpen={alarmModalOpen}
        onCloseClick={() => setAlarmModalOpen(false)}
        selectedJob={currentJob}
      />
      <AssignAssetsModalDialog
        isOpen={isAssetsDialogOpen}
        onActionClick={() => {}}
        onCloseClick={() => {
          // TODO reset diaog content if required
          setIsAssetsDialogOpen(false);
        }}
        selectedJob={currentJob}
      />
      <JobStatusModalDialog
        isOpen={isJobStatusDialogOpen}
        onCloseClick={() => setIsJobStatusDialogOpen(false)}
        selectedJob={currentJob}
      />
      <CustomModal open={showModal} onClose={hideDrillingModal}>
        <FilterCarousel
          carrouselDataSource={DRILLING_FILTERS}
          handleCardItemClick={handleCardItemClick}
        >
          <FilterCarouselItem />
        </FilterCarousel>
      </CustomModal>

      <CustomModal
        onDateTimeRangeChange={handleReportDateChange}
        open={showReportModal}
        onClose={hideReportModal}
        cta1Label={'Close'}
        showDateTimeRange={true}
        showCta2={true}
        cta2Label={'Report'}
        cta2Action={createReport}
      >
        <FilterCarousel
          carrouselDataSource={ACTUATOR_REPORT}
          handleCardItemClick={handleReportCardItemClick}
        >
          <ReportCarouselItem printIcon={false} />
        </FilterCarousel>
      </CustomModal>
    </>
  );
};

JobsGrid.propTypes = {
  row: PropTypes.object,
  height: PropTypes.string,
  stickyHeader: PropTypes.bool,
  autoHeight: PropTypes.bool,
};

export default JobsGrid;
