import { AddLocationAltRounded, AspectRatioRounded } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addNewWell } from '../../../../store/actions/wells';
import useAspectRatio from '../../../../hooks/useAspectRatio';

const WellsControls = () => {
  const dispatch = useDispatch();
  const handleAspectRatio = useAspectRatio();
  const { preferences } = useSelector((state) => state.currentUser);

  const handleAddToWells = () => {
    dispatch(addNewWell());
  };

  return (
    <Box>
      <IconButton
      edge="end"
      onClick={handleAddToWells}
    >
      <AddLocationAltRounded />
      </IconButton>
      <IconButton
        edge="end"
        onClick={() => {
          handleAspectRatio(!preferences.aspectChanged);
        }}
        sx={{ marginLeft: 2 }}
      >
        <AspectRatioRounded />
      </IconButton>
    </Box>
  );
};

export default WellsControls;
