import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  inputFieldMaxOrder: {
    fontSize: '20px',
    maxWidth: 250
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  displayQuants: {
    fontSize: '16px',
    fontWeight: 700,
    marginLeft: '1%'
  }
}));

export default styles;
