import store from '../store';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { enqueueSnackbar } from './snackbar';
import { parseError } from '../../utils/fetchErrorParser';
import fetch from '../../api/fetch';

export const listWells = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_LIST_LOADING,
  });
  return fetch
    .post('wells/fetch')
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.WELLS_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.WELLS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const addNewWell = () => (dispatch) => {
  const { wells } = store.getState();
  const newWells = [...wells.data];
  const nextId = newWells.length - 1;

  function generateRandom() {
    const length = 24;
    const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const newRow = {
    name: '',
    latitude: 0,
    longitude: 0,
    surveys: '',
    geometry: '',
    drill_string: '',
    drilling_fluid: '',
    lastUpdate: new Date(),
    lastChangedStatus: 1,
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
    wellInfo_Id: '',
    operator: '',
    rig: ''
  };
  newWells.unshift(newRow);

  dispatch({
    type: REDUX_ACTIONS.WELLS_LIST_SUCCESS,
    payload: {
      data: newWells,
      paginatedElements: newWells.length,
    },
  });
};

export const createNewWell = (body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_SAVE_DETAILS,
    payload: true
  });
  const input = {
    input: {
      name: body.name,
      latitude: body.latitude.toString(),
      longitude: body.longitude.toString(),
      wellInfo_Id: body.wellInfo_Id,
      operator: body?.operator,
      rig: body?.rig
    }
  };
  return fetch
    .post('wells/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(listWells());
      dispatch(
        enqueueSnackbar(
          'Well Added Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.WELLS_SAVE_DETAILS,
        payload: false
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const updateWells = (data) => (dispatch) => {
  const { wells } = store.getState();
  const currentWells = [...wells.data];
  const updatedWell = currentWells.filter(item => item?._id !== data);
  dispatch({
    type: REDUX_ACTIONS.WELLS_LIST_SUCCESS,
    payload: {
      data: updatedWell,
      paginatedElements: updatedWell.length,
    },
  });
};

export const updateWellDetails = (body) => (dispatch) => {
  const input = {
    input: {
      name: body.name,
      latitude: body.latitude.toString(),
      longitude: body.longitude.toString(),
      wellInfo_Id: body.wellInfo_Id,
      operator: body?.operator,
      rig: body?.rig
    },
    query: {
      _id: body._id
    }
  };
  return fetch
    .put('wells', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(listWells());
      dispatch(
        enqueueSnackbar(
          'Well Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const deleteWell = (body) => (dispatch) => {
  const input = {
    query: {
      _id: body._id
    }
  };
  return fetch
    .deleteAction('wells', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(listWells());
      dispatch(
        enqueueSnackbar(
          'Well Deleted Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};
