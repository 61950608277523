const columns = (renderCell, renderActions, nonEditableFields, renderValueCell) => [
  {
    field: 'md',
    headerName: 'MD (ft)',
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(2);
    },
  },
  {
    field: 'inc',
    headerName: 'Inc (deg.)',
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(2);
    },
  },
  {
    field: 'az',
    headerName: 'Az (deg.)',
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(2);
    },
  },
  {
    field: 'tvd',
    headerName: 'TVD (ft)',
    width: 150,
    renderEditCell: renderValueCell,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(2);
    },
  },
  {
    field: 'ns',
    headerName: 'NS (ft)',
    width: 150,
    renderEditCell: renderValueCell,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(2);
    },
  },
  {
    field: 'ew',
    headerName: 'EW (ft)',
    width: 150,
    renderEditCell: renderValueCell,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(2);
    },
  },
  {
    field: 'dls',
    headerName: 'DLS (deg/100ft)',
    width: 150,
    renderEditCell: renderValueCell,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(2);
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
