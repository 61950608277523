import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  dialogModal: {
    '& .MuiPaper-root': {
      '& .makeStyles-dialogContent-28': {
        padding: '4px 8px !important',
      },
      width: '50%',
      backgroundColor: '#f0f0f0'
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'space-between',
  },
  sliderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '7%'
  },
}));

export default styles;
