import fetchAPI from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import store from '../store';
import { enqueueSnackbar } from './snackbar';
import * as ENVIRONMENT from '../../shared/config/config';

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export const listSurvey = (wellId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_SURVEY_LIST_LOADING,
  });
  const body = {
    query: {
      wellsInfoId: wellId
    }
  };
  return fetchAPI
    .post('wells/entity/surveys/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.WELLS_SURVEY_LIST_SUCCESS,
        payload: {
          data: data.data.sort((a, b) => a.MD - b.MD),
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.WELLS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const addNewSurveyRow = () => (dispatch) => {
  const { wellsSurvey } = store.getState();
  const newSurvey = [...wellsSurvey.data];
  const nextId = newSurvey.length - 1;
  const newRow = {
    dls: '',
    md: '',
    inc: '',
    az: '',
    tvd: '',
    ns: '',
    ew: '',
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
  };
  newSurvey.unshift(newRow);
  dispatch({
    type: REDUX_ACTIONS.WELLS_SURVEY_LIST_SUCCESS,
    payload: {
      data: newSurvey,
      paginatedElements: newSurvey.length,
    },
  });
};

export const createSurvey = (body, wellId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_SURVEY_LIST_LOADING,
  });
  delete body._id;
  const input = {
    query: {
      wellsInfoId: wellId
    },
    input: {
      ...body,
    }
  };
  return fetchAPI
    .post('wells/entity/surveys/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Survey Details Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listSurvey(wellId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listSurvey(wellId));
    });
};

export const updateSurvey = (body, wellId, rowId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_SURVEY_LIST_LOADING,
  });
  const input = {
    query: {
      _id: rowId,
      wellsInfoId: wellId
    },
    input: {
      ...body
    }
  };
  return fetchAPI
    .put('wells/entity/surveys', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Survey Details Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listSurvey(wellId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listSurvey(wellId));
    });
};

export const deleteSurveyRow = (deleteSurveyId, wellId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_SURVEY_LIST_LOADING,
  });
  const body = {
    query: {
      _id: deleteSurveyId,
      wellsInfoId: wellId
    }
  };
  return fetchAPI
    .deleteAction('wells/entity/surveys', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Survey Details Deleted Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(listSurvey(wellId));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(listSurvey(wellId));
    });
};

export const updateSurveyDetails = (data) => (dispatch) => {
  const { wellsSurvey } = store.getState();
  const currentSurvey = [...wellsSurvey.data];
  const index = currentSurvey.findIndex(survey => survey._id === data._id);
  currentSurvey[index] = data;
  dispatch({
    type: REDUX_ACTIONS.WELLS_SURVEY_LIST_SUCCESS,
    payload: {
      data: currentSurvey.sort((a, b) => a.md - b.md),
      paginatedElements: currentSurvey.length,
    },
  });
};

export const importSurveyDetails = (data) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_SURVEY_LIST_LOADING,
  });
  const surveyList = data.sort((a, b) => a.md - b.md).map((value, index) => {
    return {
      ...value,
      id: generateRandom(),
      grid_id: index,
      _id: index
    };
  });
  setTimeout(() => {
    dispatch({
      type: REDUX_ACTIONS.WELLS_SURVEY_LIST_SUCCESS,
      payload: {
        data: surveyList,
        paginatedElements: surveyList.length,
      }
    });
    dispatch(
      enqueueSnackbar(
        'CSV file imported Successfully.',
        'success',
        new Date().getTime() + Math.random()
      ));
  }, 2000);
};

export const importWellsSurveyCSVFIle = (file, wellId) => async (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.WELLS_SURVEY_LIST_LOADING,
  });
  const formData = new FormData();
  const { currentUser } = store.getState();
  const ipDetails = {
    ip: currentUser?.ip,
  };
  const BASE_URL = ENVIRONMENT.API_URL;
  formData.append('file', file);
  const payload = {
    query: {
      wellsInfoId: wellId
    },
    user: {
      type: currentUser.type,
      companyId: currentUser.companyId,
      userId: currentUser.userId,
      ...(ipDetails?.ip && { ...ipDetails })
    },
  };
  formData.append('payload', JSON.stringify(payload));
  try {
    const response = await fetch(`${BASE_URL}wells/entity/survey/import`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
    if (response.status !== 200) {
      const errorData = await response.json();
      const errorMessage = errorData.message;
      throw new Error(errorMessage);
    }
    dispatch(
      enqueueSnackbar(
        'CSV Imported Successfully',
        'success',
        new Date().getTime() + Math.random()
      )
    );
    dispatch(listSurvey(wellId));
  } catch (error) {
    let errorMessage = '';

    if (typeof error === 'string') {
      errorMessage = error;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    } else if (error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = 'An unknown error occurred';
    }

    const extractedMessage = errorMessage.split(' at importSurveysDataFromCSV')[0].trim();

    dispatch(
      enqueueSnackbar(
        extractedMessage,
        'error',
        new Date().getTime() + Math.random()
      )
    );
    dispatch(listSurvey(wellId));
  }
};
