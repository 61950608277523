import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Container, Grid, useMediaQuery } from '@mui/material';
import {
  Jobs,
  Login,
  Order,
  Rigs,
  Quote,
  Alarms,
  FieldTickets,
  Trucks,
} from './components';
import AuditLog from './components/auditLog';
import TransactionHistory from './components/transactionHistory';
import AlarmHistory from './components/alarmHistory';
import TruFlowSettings from './components/truFlowSettings';
import Company from './components/companies';
import Part from './components/parts';
import AssetData from './components/assetData';
import Users from './components/users/mpd';
import PrivacyPolicy from './components/privacyPolicy';
import Unauthorized from './components/unauthorized';
import NotFound from './components/404';
import ResetPasswordSendEmail from './components/authentication/resetPasswordSendEmail';
import ResetPassword from './components/authentication/resetPassword';
import SignUp from './components/authentication/signUp';
import AuthProvider from './context/AuthProvider';
import Layout from './components/layouts/default';
import EpicorParts from './components/epicor/epicorParts';
import EpicorCustomers from './components/epicor/epicorCustomers';
import MarketplaceTransactions from './components/marketplaceTransactions';
import { useDispatch, useSelector } from 'react-redux';
import { partCategories } from './store/actions/parts';
import EpicorPriceLists from './components/epicor/epicorPriceLists/index';
import EpicorSaleOrders from './components/epicor/epicorSaleOrders/index';
import EpicorWorkForce from './components/epicor/epicorWorkForce/index';
import EpicorBins from './components/epicor/epicorBins/index';
import { USER_TYPE } from './utils/constants';
import Wells from './components/wells';
import PartsMobile from './components/mobile/parts';
import { isMobile } from 'react-device-detect';
import LandscapeMessage from './components/mobile/mobilePortaraitView';
import OrderStatus from './components/orderStatus';
import EnverusRigs from './components/enverus/rigs';
import AssetTypes from './components/assetTypes';
import EnverusWells from './components/enverus/wells';
import CheckIns from './components/checkIns';
import { REDUX_ACTIONS } from './shared/config/constants';
import { FETCH_IP_URL } from './shared/config/config';
import TransactionHistoryKPI from './components/transactionHistoryKPI';
import CheckInsKPI from './components/checkInsKPI';
import fetch from './api/fetch';
import JobsMobile from './components/mobile/jobs';
import AssetDataMobile from './components/mobile/assetData';
import Marketplace from './components/marketplace';
import FieldBoxesKPI from './components/fieldboxesKPI';
import MarketplaceLogs from './components/marketplaceLogs';

const AppRoutes = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.currentUser);
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const mobile = isMobile;
  const [ipFetched, setIpFetched] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (user.logged) {
      if (
        user.type === USER_TYPE.PREMIUM_ADMIN ||
        user.type === USER_TYPE.CUSTOMER_MANAGER ||
        user.type === USER_TYPE.CUSTOMER_ADMIN ||
        user.type === USER_TYPE.CUSTOMER_USER ||
        user.type === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN ||
        user.type === USER_TYPE.SELLER_MARKETPLACE_ADMIN ||
        user.type === USER_TYPE.CUSTOMER_RIG
      ) {
        dispatch(partCategories());
      }
    }
  }, [user.logged]);

  const getIpDetails = async () => {
    await fetch
      .neutralGet(FETCH_IP_URL, {}, 1000)
      .then((result) => {
        if (!result.ok) {
          return Promise.reject(result);
        }
        return result.json();
      })
      .then((data) => {
        dispatch({
          type: REDUX_ACTIONS.SET_IP_DETAILS,
          payload: {
            ip: data?.ip,
          }
        });
        setIpFetched(true);
      })
      .catch(async (error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIpFetched(true);
      });
  };

  useEffect(() => {
    getIpDetails();
  }, []);

  const mobileContainerStyles = mobile
    ? {
        margin: 0,
        padding: 0
      }
    : {};

  if (!ipFetched) {
    return <div />;
  }

  if (location.pathname === '/MKT') {
    return (
      <Grid>
        <Routes>
          <Route path="/MKT" element={<Marketplace />} />
        </Routes>
      </Grid>
    );
  }

  return (
    <Container
      maxWidth={user.preferences.aspectRatioPage.maxWidth}
      style={{ ...user.preferences.aspectRatioPage.style, ...mobileContainerStyles }}
      sx={{ paddingLeft: mobile ? '0px !important' : '', paddingRight: mobile ? '0px !important' : '' }}
    >
      <AuthProvider>
          {isLandscape && mobile
            ? <LandscapeMessage/>
            : <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="Jobs" element={mobile ? <JobsMobile /> : <Jobs />} />
            <Route path="Alarms" element={<Alarms />} />
            <Route path="Orders" element={<Order />} />
            <Route path="Quotes" element={<Quote />} />
            <Route path="Shop" element={mobile ? <PartsMobile /> : <Part />} />
            <Route path="Companies" element={<Company />} />
            <Route path="Users" element={<Users />} />
            <Route path="Data" element={mobile ? <AssetDataMobile /> : <AssetData />} />
            <Route path="Epicor/Parts" element={<EpicorParts />} />
            <Route path="Epicor/Customers" element={<EpicorCustomers />} />
            <Route path="Epicor/PriceLists" element={<EpicorPriceLists />} />
            <Route path="Epicor/SaleOrders" element={<EpicorSaleOrders />} />
            <Route path="Epicor/WorkForce" element={<EpicorWorkForce />} />
            <Route path="Epicor/Bins" element={<EpicorBins />} />
            <Route path="FieldBoxes" element={<Rigs />} />
            <Route path="FieldBoxes/KPIs" element={<FieldBoxesKPI />} />
            <Route path="Wells" element={<Wells />} />
            <Route path="FieldTickets" element={<FieldTickets />} />
            <Route path="Trucks" element={<Trucks />} />
            <Route path="AuditLog" element={<AuditLog />} />
            <Route path="MarketplaceLog" element={<MarketplaceLogs />} />
            <Route path="TransactionHistory" element={<TransactionHistory />} />
            <Route path="AlarmHistory" element={<AlarmHistory />} />
            <Route path="TruFlowSettings" element={<TruFlowSettings />} />
            <Route path="MarketplaceTransactions" element={<MarketplaceTransactions />} />
            <Route path="Assets/Records" element={''}/>
            <Route path="Enverus/Rigs" element={<EnverusRigs />}/>
            <Route path="Enverus/Wells" element={<EnverusWells />}/>
            <Route path="Assets/Types" element={<AssetTypes />}/>
            <Route path="CheckIns" element={<CheckIns />}/>
            <Route path="TransactionHistory/KPIs" element={<TransactionHistoryKPI/>}/>
            <Route path="CheckIns/KPIs" element={<CheckInsKPI />}/>
          </Route>
          <Route path="/Login" element={<Login />} />
          <Route path="/MKT" element={<Marketplace />} />
          <Route
            path="/ResetPasswordSendEmail"
            element={<ResetPasswordSendEmail />}
          />
          <Route path="/Recovery" element={<ResetPassword />} />
          <Route path="/SignUp" element={<SignUp />} />
          <Route path="/order-status" element={<OrderStatus />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/Unauthorized" element={<Unauthorized />} />
        </Routes>}
      </AuthProvider>
    </Container>
  );
};

export default AppRoutes;
