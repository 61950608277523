import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import fetch from '../../api/fetch';
import { enqueueSnackbar } from './snackbar';
import store from '../store';

export const listCheckIns = (payload) => (dispatch) => {
  const { checkIns } = store.getState();

  dispatch({
    type: REDUX_ACTIONS.CHECKINS_LIST_LOADING,
  });
  const body = {
    input: {
      searchValue: payload?.search ? payload?.search : checkIns?.searchKey || '',
      startDate: payload?.selectedStartDateTime ? payload?.selectedStartDateTime : checkIns?.startDate || '',
      endDate: payload?.selectedEndDateTime ? payload?.selectedEndDateTime : checkIns?.endDate || '',
      filters: checkIns?.filters?.items || [],
      limit: 100,
      page: payload?.page || 1,
      linkOperator: checkIns?.filters?.linkOperator || '',
      sort: checkIns?.sort || null,
      division: payload?.division ? payload?.division : checkIns?.division || '',
    }
  };
  return fetch
    .post('checkins/fetch', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_LIST_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.count,
          totalCount: data.count
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_LIST_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const syncCheckIns = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.CHECKINS_SYNC_LOADING,
    payload: {
      message: 'Please wait...',
    },
  });

  return fetch
    .post('enverus/syncWells')
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(async (data) => {
      await dispatch(listCheckIns());
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_SYNC_IDLE,
        payload: {
          message: data.message,
        },
      });
    })
    .catch(async (error) => {
      dispatch(
        enqueueSnackbar(
          error.message || 'Error syncing wells',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_SYNC_ERROR,
        payload: {
          message: error.message || 'Error syncing wells',
        },
      });
    });
};

export const fetchCheckInsDetail = (body) => (dispatch) => {
  return fetch
    .post('enverus/wells/fetch-one', { input: body })
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_DETAILS_SUCCESS,
        payload: data,
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_DETAILS_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const getCheckInsEmailKPIs =
  (startDate = null, endDate = null) =>
    (dispatch) => {
      dispatch({
        type: REDUX_ACTIONS.CHECK_INS_EMAIL_KPIS_LOADING,
      });

      const body = {
        input: {
          startDate, endDate
        }
      };

      const path = 'checkins/email-KPIs';
      return fetch
        .post(path, body)
        .then((resp) => {
          if (!resp.ok) {
            return Promise.reject(resp);
          }
          return resp.json();
        })
        .then((data) => {
          const chartData = data.map((item, index) => {
            return { ...item, y: item.count, label: item.UserEmail, _id: index, division: item.division || 'Other' };
          });
          dispatch({
            type: REDUX_ACTIONS.CHECK_INS_EMAIL_KPIS_SUCCESS,
            payload: chartData
          });
        })
        .catch(async (error) => {
          const errorMessage = await parseError(error);

          dispatch(
            enqueueSnackbar(
              errorMessage,
              'error',
              new Date().getTime() + Math.random()
            )
          );
          dispatch({
            type: REDUX_ACTIONS.CHECK_INS_EMAIL_KPIS_ERROR,
            payload: { ...error, message: errorMessage },
          });
        });
    };

export const getCheckInsDivisionKPIs =
    (startDate = null, endDate = null) =>
      (dispatch) => {
        dispatch({
          type: REDUX_ACTIONS.CHECK_INS_DIVISION_KPIS_LOADING,
        });

        const body = {
          input: {
            startDate, endDate
          }
        };

        const path = 'checkins/division-KPIs';
        return fetch
          .post(path, body)
          .then((resp) => {
            if (!resp.ok) {
              return Promise.reject(resp);
            }
            return resp.json();
          })
          .then((data) => {
            const chartData = data.map((item, index) => {
              return { ...item, y: item.count, _id: index, division: item.division };
            });
            dispatch({
              type: REDUX_ACTIONS.CHECK_INS_DIVISION_KPIS_SUCCESS,
              payload: chartData
            });
          })
          .catch(async (error) => {
            const errorMessage = await parseError(error);

            dispatch(
              enqueueSnackbar(
                errorMessage,
                'error',
                new Date().getTime() + Math.random()
              )
            );
            dispatch({
              type: REDUX_ACTIONS.CHECK_INS_DIVISION_KPIS_ERROR,
              payload: { ...error, message: errorMessage },
            });
          });
      };
