import { USER_TYPE } from '../../utils/constants';

const columns = (renderCell, renderSellersCell, renderInventoryCell, renderActionCell, renderTypeCell, renderContactCell, userType) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 400,
    hide: false,
    renderCell,
    editable: userType === USER_TYPE.PREMIUM_ADMIN,
    headerAlign: 'center',
  },
  {
    field: 'legalName',
    headerName: 'Legal Name',
    width: 400,
    // eslint-disable-next-line
    hide: userType === USER_TYPE.PREMIUM_ADMIN ? false : true,
    renderCell,
    editable: userType === USER_TYPE.PREMIUM_ADMIN,
    headerAlign: 'center',
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 300,
    // eslint-disable-next-line
    hide: userType === USER_TYPE.PREMIUM_ADMIN ? false : true,
    renderEditCell: renderTypeCell,
    editable: userType === USER_TYPE.PREMIUM_ADMIN,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 120,
    renderCell,
    editable: userType === USER_TYPE.PREMIUM_ADMIN,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'state',
    headerName: 'State',
    width: 120,
    renderCell,
    editable: userType === USER_TYPE.PREMIUM_ADMIN,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 200,
    // eslint-disable-next-line
    hide: userType === USER_TYPE.PREMIUM_ADMIN ? false : true,
    renderCell,
    editable: userType === USER_TYPE.PREMIUM_ADMIN,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'address1',
    headerName: 'Address',
    width: 400,
    hide: false,
    renderCell,
    editable: userType === USER_TYPE.PREMIUM_ADMIN,
    headerAlign: 'center',
  },
  {
    field: 'postalCode',
    headerName: 'Zip Code',
    width: 200,
    hide: false,
    renderCell,
    editable: userType === USER_TYPE.PREMIUM_ADMIN,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'sellers',
    headerName: 'Sellers',
    width: 200,
    // eslint-disable-next-line
    hide: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN ? false : true,
    renderCell: renderSellersCell,
    headerAlign: 'center',
  },
  {
    field: 'Inventory',
    headerName: 'Inventory',
    width: 200,
    // eslint-disable-next-line
    hide: userType === USER_TYPE.PREMIUM_ADMIN || userType === USER_TYPE.PREMIUM_MARKETPLACE_ADMIN  || userType === USER_TYPE.SELLER_MARKETPLACE_ADMIN || userType === USER_TYPE.CUSTOMER_MANAGER || userType === USER_TYPE.CUSTOMER_ADMIN || userType === USER_TYPE.CUSTOMER_USER || userType === USER_TYPE.CUSTOMER_RIG ? false : true,
    renderCell: renderInventoryCell,
    headerAlign: 'center',
  },
  {
    field: 'Contact',
    headerName: 'Contact',
    width: 200,
    // eslint-disable-next-line
    hide: userType === USER_TYPE.CUSTOMER_MANAGER || userType === USER_TYPE.CUSTOMER_ADMIN || userType === USER_TYPE.CUSTOMER_USER || userType === USER_TYPE.CUSTOMER_RIG ? false : true,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderContactCell,
  },
  {
    field: 'action',
    headerName: 'Action',
    width: 200,
    headerAlign: 'center',
    renderCell: renderActionCell,
    hide: userType !== USER_TYPE.PREMIUM_ADMIN,
    align: 'center'
  }
];

export default columns;
