import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({
  modalTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
  },
  dialogModal: {
    '& .MuiPaper-root': {
      marginLeft: '10px',
      '& .makeStyles-dialogTitle-37': {
        padding: '16px 12px !important',
      }
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '1.3rem',
      fontWeight: 'bold',
    }
  },
  headerCell: {
    border: '2px solid white',
    backgroundColor: '#cccccc',
    color: 'black',
    fontWeight: 'bold',
    padding: '4px',
    height: '20px'
  },
  rowCell: {
    border: '2px solid white',
    backgroundColor: '#e0e0e0',
    color: 'black',
    padding: '4px',
    height: '20px'
  },
  tableContainer: {
    maxWidth: 300,
    height: 'auto',
    maxHeight: 600
  }
}));
export default styles;
